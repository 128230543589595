import { Permission } from '../enums';
import { IPermission } from '../models';

export const PERMISSIONS: IPermission[] = [
  { key: Permission.USERS_CREATE, desc: 'Invite a new user to the organisation' },
  { key: Permission.USERS_READ, desc: 'See the Users admin page and all user details' },
  { key: Permission.USERS_UPDATE, desc: 'Update an existing user' },
  { key: Permission.USERS_DELETE, desc: 'Delete an existing user' },
  { key: Permission.GROUPS_CREATE, desc: 'Create a new user group' },
  { key: Permission.GROUPS_READ, desc: 'See the Groups admin page and all group details' },
  { key: Permission.GROUPS_UPDATE, desc: 'Update an existing group' },
  { key: Permission.GROUPS_DELETE, desc: 'Delete an existing group' },
  { key: Permission.ROLES_CREATE, desc: 'Create a new role' },
  { key: Permission.ROLES_READ, desc: 'See the Roles admin page and all role details' },
  { key: Permission.ROLES_UPDATE, desc: 'Update an existing role' },
  { key: Permission.ROLES_DELETE, desc: 'Delete an existing role' },
  { key: Permission.ALERTS_CREATE, desc: 'Create a new alert' },
  { key: Permission.ALERTS_READ, desc: 'See the Alerts admin page and all alert details' },
  { key: Permission.ALERTS_UPDATE, desc: 'Update an existing alert' },
  { key: Permission.ALERTS_DELETE, desc: 'Delete an existing alert' },
  { key: Permission.AUDIT_LOG_READ, desc: 'See the Audit Log page and all audit log details' },
  { key: Permission.TRANSACTIONS_READ, desc: 'See the Transactions page and all process details' },
  { key: Permission.DATA_COLLECTION_READ, desc: 'See the Data Collection page and all data collection details' },
  { key: Permission.WAREHOUSE_READ, desc: 'See the Warehouse list and all Warehouse details' },
  { key: Permission.WAREHOUSE_CREATE, desc: 'Create a Warehouse' },
  { key: Permission.WAREHOUSE_UPDATE, desc: 'Update an existing Warehouse' },
  { key: Permission.WAREHOUSE_DELETE, desc: 'Delete an existing Warehouse' },
  { key: Permission.TEMPLATE_READ, desc: 'See the templates page with preview and download' },
  { key: Permission.API_KEYS_READ, desc: 'Access and view Integrations Page / API keys'},
  { key: Permission.API_KEYS_MANAGE, desc: 'Manage API Keys'}
];
