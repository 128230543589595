export interface ApiKey {
  key: string;
  description?: string | null;
  partnerId: string;
  name: string;
  createdAt: string;
  awsKeyId: string;
  enabled: boolean;
  expiresAt?: Date | null;
  id: string;
}
