import { Injectable } from '@angular/core';
import { first, Observable } from 'rxjs';
import { ServiceCreate, ServiceDelete, ServiceList, ServiceUpdate, ServiceGet } from '@core/models';
import { ApiKey } from '@core/models/api-key.model';
import { ApiService, LoadingService, SnackbarService } from '../../services';
import { ApiKeysLoaderKey } from '@core/enums/api-key.enum';

const ENDPOINT = 'v1/api-key';

@Injectable({ providedIn: 'root' })
export class ApiKeysAPIService
implements
    ServiceGet<ApiKey>,
    ServiceUpdate<ApiKey, Partial<ApiKey>>,
    ServiceList<ApiKey[]>,
    ServiceCreate<ApiKey, Partial<ApiKey>>,
    ServiceDelete<{ id: string }>
{
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  get(id: string): Observable<ApiKey> {
    return this.api
      .get<ApiKey>(`${ENDPOINT}/${id}`)
      .pipe(first(), this.loader.set(ApiKeysLoaderKey.GET_API_KEY), this.snack.operator());
  }
  list(): Observable<ApiKey[]> {
    return this.api
      .get<ApiKey[]>(ENDPOINT)
      .pipe(first(), this.loader.set(ApiKeysLoaderKey.LIST_API_KEYS), this.snack.operator());
  }

  create(payload: Partial<ApiKey>): Observable<ApiKey> {
    return this.api
      .post<ApiKey, Partial<ApiKey>>(ENDPOINT, payload)
      .pipe(first(), this.loader.set(ApiKeysLoaderKey.CREATE_API_KEY), this.snack.operator('API Key created'));
  }

  update(id: string, payload: Partial<ApiKey>): Observable<ApiKey> {
    return this.api
      .put<ApiKey, Partial<ApiKey>>(ENDPOINT, id, payload)
      .pipe(first(), this.loader.set(ApiKeysLoaderKey.UPDATE_API_KEY), this.snack.operator('API Key updated'));
  }

  delete(id: string): Observable<{ id: string }> {
    return this.api
      .delete<{ id: string }>(ENDPOINT, id)
      .pipe(first(), this.loader.set(ApiKeysLoaderKey.DELETE_API_KEY), this.snack.operator('API Key deleted'));
  }
}
