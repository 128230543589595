import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, switchMap, tap } from 'rxjs';

import { ApiKey } from '../../models';
import { ApiKeysAPIService } from '../../services';

@Injectable({ providedIn: 'root' })
export class ApiKeysService {
  get apiKeysList$(): Observable<ApiKey[]> {
    return this.#apiKeysList$.asObservable();
  }

  get apiKey$(): Observable<ApiKey> {
    return this.#apiKey$.asObservable();
  }

  readonly #apiKeysList$ = new BehaviorSubject<ApiKey[]>([]);
  readonly #apiKey$ = new BehaviorSubject<ApiKey>({} as ApiKey);

  constructor(private apiKeysApiService: ApiKeysAPIService) {}

  getById(roleId: string): Observable<ApiKey> {
    return this.apiKeysApiService.get(roleId).pipe(tap((keys) => this.#apiKey$.next(keys)));
  }

  getAll(): Observable<ApiKey[]> {
    return this.apiKeysApiService.list().pipe(tap((keys) => this.#apiKeysList$.next(keys)));
  }

  create(payload: Partial<ApiKey>): Observable<ApiKey[]> {
    return this.apiKeysApiService.create(payload).pipe(switchMap(() => this.getAll()));
  }

  update(roleId: string, payload: Partial<ApiKey>): Observable<ApiKey[]> {
    return this.apiKeysApiService.update(roleId, payload).pipe(
      switchMap(() => forkJoin([this.getAll(), this.getById(roleId)])),
      map(([keys]) => keys),
    );
  }

  delete(roleId: string): Observable<ApiKey[]> {
    return this.apiKeysApiService.delete(roleId).pipe(switchMap(() => this.getAll()));
  }
}

